export const DataManager = {
    _callbacks : [],
    keys : {
        sessionToken : (process.env.REACT_APP_MODE === "cms" ? "cms_" : "") + "constructClient_sessionToken",
        user : (process.env.REACT_APP_MODE === "cms" ? "cms_" : "") + "constructClient_user",
        project : (process.env.REACT_APP_MODE === "cms" ? "cms_" : "") + "constructClient_project"
    },
    getSessionToken : () => {
        return DataManager.getItem(DataManager.keys.sessionToken, null);
    },
    setSessionToken : (token) => {
        return DataManager.setItem(DataManager.keys.sessionToken, token);
    },
    getUser : () => {
        return DataManager.getJsonItem(DataManager.keys.user);
    },
    setUser : (user) => {
        return DataManager.setJsonItem(DataManager.keys.user, user);
    },
    getSelectedProject : () => {
        return DataManager.getJsonItem(DataManager.keys.project);
    },
    setSelectedProject : (project) => {
        return DataManager.setJsonItem(DataManager.keys.project, project);
    },
    getItem : (keyName, defaultValue) => {
        if (defaultValue === undefined) defaultValue = null;

        let item = localStorage.getItem(keyName);
        if (item != null) {
            return item;
        }
        return defaultValue;
    },
    setItem : (keyName, value) => {
        localStorage.setItem(keyName, value);
        DataManager._triggerCallbacks(keyName, value);
        return true;
    },
    getJsonItem : (keyName) => {
        let item = DataManager.getItem(keyName);
        if (item != null) {
            try {
                item = JSON.parse(item);
                return item;
            } catch (e) {
                console.log(e);
            }
        }
        return null;
    },
    setJsonItem : (keyName, item) => {
        return DataManager.setItem(keyName, JSON.stringify(item));
    },
    clear : () => {
        for (const [key, value] of Object.entries(DataManager.keys)) {
            localStorage.removeItem(value);
        }
        return true;
    },
    addCallback : (callback) => {
        if (callback instanceof Function) {
            DataManager._callbacks.push(callback);
        }
    },
    removeCallback : (callback) => {
        for (let i = 0; i < DataManager._callbacks.length; i++) {
            if (DataManager._callbacks[i] === callback) {
                delete DataManager._callbacks[i];
                break;
            }
        }
    },
    _triggerCallbacks : (keyName, value) => {
        DataManager._callbacks.forEach((callback) => {
            callback(keyName, value);
        });
    }
}
