import BaseComponent from "../../../../BaseComponent";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../../network/API";
import { v4 as uuidv4 } from "uuid";

import "./FormEditorComponent.css";

import arrowUp from "../../../../../assets/images/arrow_up.svg";
import arrowDown from "../../../../../assets/images/arrow_down.svg";
import trash from "../../../../../assets/images/delete.svg";
import edit from "../../../../../assets/images/edit.svg"
import addIcon from "../../../../../assets/images/add.svg";

import FormSectionEditorComponent from "./FormSectionEditorComponent";
import FormQuestionInputEditor from "./FormQuestionInputEditor";
import FormInputComponent from "./inputs/FormInputComponent";
import ArrayUtil from "../../../../../util/ArrayUtil";
import FormSubmissionModalComponent from "./FormSubmissionModalComponent";
import WindowUtil from "../../../../../util/WindowUtil";
import Rosetta from "../../../../../rosetta/Rosetta";
import AlertModal from "../../../../alertmodal/AlertModal";
import {ClientSelectionModal} from "../../../common/ClientSelectionModal";
import {OffcanvasActions} from "../../../../offcanvas/Offcanvas";
import {CommonUtil} from "../../../../../util/CommonUtil";

export default class FormEditorComponent extends BaseComponent {

    constructor(props, context) {
        super(props, context);

        let formId = undefined;
        if (props.hasOwnProperty("formID") && props.formID !== "new") {
            formId = parseInt(props.formID);
        }

        let form = null;
        if (formId === undefined || formId === "new") {
            form = {
                title : "",
                sections : []
            };
        }

        this.initState({
            form,
            formId,
            published : false,
            submissionModalShown : false,
            submissionModalMode : FormSubmissionModalComponent.MODE_PROMPT,
            formSubmissionModalError : "",
            deletedSectionIds : [],
            deletedQuestionIds : [],
            deletedQuestionOptionIds : [],
            locationVisibilityEditorShown : false,
            clientSelectionShown : false
        });
    }

    componentDidMount() {
        WindowUtil.scrollToTop();

        if (this.state.formId !== undefined && this.state.formId !== "new") {
            this.fetchFormFromNetwork();
        }
    }

    populateFormIntoState = (form) => {
        let formId = this.state.formId;
        let formVisibility = undefined;
        if (form != null) {
            formId = form.id;

            if (form.hasOwnProperty("sections")) {
                form.sections.forEach((section) => {
                    section.localId = uuidv4();

                    if (section.hasOwnProperty("questions")) {
                        section.questions.forEach((question) => {
                            question.localId = uuidv4();

                            if (question.hasOwnProperty("options")) {
                                question.options.forEach((option) => {
                                    option.localId = uuidv4();
                                });
                            }
                        });

                    }
                });
            } else {
                // Huh, well, just create it now.
                form.sections = [];
            }

            if (form.hasOwnProperty("locationVisibility")) {
                formVisibility = form.locationVisibility;
            }
        }

        console.log("POPULATE INTO STATE");
        console.log(form);

        this.setState({
            formId,
            form,
            formVisibility
        });
    }

    // FIND

    findSection = (sectionLocalId) => {
        if (this.state.form.hasOwnProperty("sections")) {
            for (let i = 0; i < this.state.form.sections.length; i++) {
                if (this.state.form.sections[i].localId === sectionLocalId) {
                    return this.state.form.sections[i];
                }
            }
        }
        return null;
    }

    findSectionQuestion = (sectionLocalId, questionLocalId) => {
        let section = this.findSection(sectionLocalId);
        if (section != null) {
            if (section.hasOwnProperty("questions")) {
                for (let i = 0; i < section.questions.length; i++) {
                    let question = section.questions[i];
                    if (question.localId === questionLocalId) {
                        return question;
                    }
                }
            }
        }
        return null;
    }

    findSectionQuestionOption = (sectionLocalId, questionLocalId, optionLocalId) => {
        let question = this.findSectionQuestion(sectionLocalId, questionLocalId);
        if (question !== null) {
            if (question.hasOwnProperty("options") && question.options !== undefined) {
                for (let i = 0; i < question.options.length; i++) {
                    let option = question.options[i];
                    if (option.localId === optionLocalId) {
                        return option;
                    }
                }
            }
        }
        return null;
    }

    // CREATE

    addSection = () => {
        if (!this.state.form.hasOwnProperty("sections")) {
            this.state.form.sections = [];
        }

        this.state.form.sections.push({
            localId : uuidv4(),
            title : "",
            collapsable : 0,
            questions : []
        });

        this.forceStateUpdate();
    }

    addSectionQuestion = (sectionLocalId) => {
        let section = this.findSection(sectionLocalId);
        if (section != null) {
            if (!section.hasOwnProperty("questions")) {
                section.questions = [];
            }

            section.questions.push({
                localId : uuidv4(),
                title : "",
                allowRemedialAction : 0,
                isDuplicator : 0,
                active : 1,
                inputs : []
            });
        }

        this.forceStateUpdate();
    }

    addSectionQuestionOption = (sectionLocalId, questionLocalId) => {
        let question = this.findSectionQuestion(sectionLocalId, questionLocalId);
        if (question != null) {
            if (!question.hasOwnProperty("options")) {
                question.options = [];
            }

            question.options.push({
                localId: uuidv4(),
                label : "",
                value : "",
                active : 1
            });
        }

        this.forceStateUpdate();
    }

    // Update

    getFormProperty = (propertyName, defaultValue) => {
        if (this.state.form !== undefined && this.state.form !== null) {
            if (this.state.form[propertyName] !== undefined) {
                return this.state.form[propertyName];
            }
        }
        return defaultValue;
    }

    setFormProperty = (propertyName, propertyValue) => {
        if (this.state.form !== null) {
            this.state.form[propertyName] = propertyValue;
        }

        this.forceStateUpdate();
    }

    setSectionProperty = (sectionLocalId, propertyName, propertyValue) => {
        let section = this.findSection(sectionLocalId);
        if (section != null) {
            section[propertyName] = propertyValue;
        }

        this.forceStateUpdate();
    }

    setSectionQuestionProperty = (sectionLocalId, questionLocalId, propertyName, propertyValue) => {
        let question = this.findSectionQuestion(sectionLocalId, questionLocalId);
        if (question != null) {
            question[propertyName] = propertyValue;
        }

        this.forceStateUpdate();
    }


    setSectionQuestionOptionProperty = (sectionLocalId, questionLocalId, optionLocalId, propertyName, propertyValue) => {
        let option = this.findSectionQuestionOption(sectionLocalId, questionLocalId, optionLocalId);
        if (option != null) {
            option[propertyName] = propertyValue;
        }

        this.forceStateUpdate();
    }

    // Force State to be updated when other data is mutated without calling setState()
    forceStateUpdate = () => {
        this.setState({
            internalUpdateRef : Math.random()
        });
    }

    fetchFormFromNetwork = () => {
        if (this.state.formNetworkInFlight) return;

        this.setState({
            formNetworkInFlight : true
        });

        let formData = new FormData();
        formData.append("formID", this.state.formId);
        formData.append("activeOnly", 0);
        formData.append("publishedOnly", 0);

        Axios.post(ENDPOINTS.form.getForm, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    this.populateFormIntoState(r.data.form);
                } else {
                    AlertModal.showModal(Rosetta.string("common.error"), API.formatError(resp));
                }

                this.setState({
                    formNetworkInFlight : false
                });
            })
            .catch((e) => {
                console.log(e);
                AlertModal.showModal(Rosetta.string("common.error"), Rosetta.string("common.error_common_unknown"));
            });
    }

    submitFormOverNetwork = (published) => {
        if (this.state.formNetworkInFlight) return;

        this.setState({
            formNetworkInFlight : true,
            published : published
        });

        let formData = new FormData();
        formData.append("data", JSON.stringify(this.state.form));
        formData.append("publish", published ? "1" : "0");

        // if (this.state.deletedSectionIds !== undefined) {
        //     formData.append("deletedSectionIds", JSON.stringify(this.state.deletedSectionIds));
        // }
        //
        // if (this.state.deletedQuestionIds !== undefined) {
        //     formData.append("deletedQuestionIds", JSON.stringify(this.state.deletedQuestionIds));
        // }
        //
        // if (this.state.deletedInputOptionIds !== undefined) {
        //     formData.append("deletedInputOptionIds", JSON.stringify(this.state.deletedInputOptionIds));
        // }

        Axios.post(ENDPOINTS.form.submitFormTemplate, formData)
            .then((r) => {
                let submissionModalMode = this.state.submissionModalMode;
                let formSubmissionModalError = undefined;

                let resp = API.parse(r);
                if (resp.success) {
                    submissionModalMode = FormSubmissionModalComponent.MODE_COMPLETE;
                    this.populateFormIntoState(resp.data.form);
                } else {
                    submissionModalMode = FormSubmissionModalComponent.MODE_ERROR;
                    formSubmissionModalError = API.formatError(resp);
                }

                this.setState({
                    formNetworkInFlight : false,
                    formSubmissionModalError,
                    submissionModalMode
                });
            })
            .catch((e) => {
                console.log(e);
                this.setState({
                    formSubmissionModalError : Rosetta.string("common.error_common_unknown")
                })
            })
    }

    /*
     * SECTION
     */

    formSectionEditorWasRequested = (section) => {
        this.setState({
            formSectionEditorShown : true,
            focusedFormSection : section
        });
    }

    sectionDidChange = (section) => {
        console.log("SECTION DID CHANGE");
        console.log(section);

        if (section !== undefined) {
            let sections = this.state.form.sections;
            if (section.localId !== undefined) {
                for (let i = 0; i < sections.length; i++) {
                    if (sections[i].localId === section.localId) {
                        sections[i].title = section.title;
                        sections[i].dataSource = section.dataSource;
                        sections[i].collapsable = section.collapsable;
                        if (section.hasOwnProperty("specialOption")) {
                            sections[i].specialOption = section.specialOption;
                        }
                        break;
                    }
                }
            } else {
                section.localId = uuidv4();
                sections.push(section);
            }
        }

        this.setState({
            formSectionEditorShown : false
        });
    }

    moveSection = (sectionLocalId, direction) => {
        let currentSectionIndex = ArrayUtil.findObjectIndex(this.state.form.sections, "localId", sectionLocalId);

        if (currentSectionIndex >= 0) {
            let newIndex = currentSectionIndex + direction;
            if (newIndex < 0 || newIndex > (this.state.form.sections.length - 1)) {
                // If we are attempting to move beyond array bounds, abort
                return false;
            }

            this.state.form.sections = ArrayUtil.arrayMoveImmutable(this.state.form.sections, currentSectionIndex, newIndex);
        }

        this.forceStateUpdate();
        return true;
    }

    deleteSection = (section, confirmed) => {
        if (confirmed === undefined) {
            confirmed = false;
        }

        if (!confirmed) {
            AlertModal.showModal(
                Rosetta.string("form_editor.section_delete_title"),
                Rosetta.string("form_editor.section_delete_message", {title : section.title}),
                [
                    {
                        label : Rosetta.string("common.yes"),
                        className : "danger",
                        click : () => this.deleteSection(section, true)
                    },
                    {
                        label : Rosetta.string("common.no"),
                        click : () => AlertModal.dismissModal()
                    }
                ]
            );
        } else {
            AlertModal.dismissModal();

            // Add Question ID to array
            let deletedSectionIds = this.state.deletedSectionIds;
            if (section.id !== undefined && section.id !== null) {
                deletedSectionIds.push(section.id);
            }

            // Remove Question from Form
            let questionIndex = ArrayUtil.findObjectIndex(this.state.form.sections, "localId", section.localId);
            if (questionIndex >= 0) {
                this.state.form.sections.splice(questionIndex, 1);
            }

            this.setState({
                deletedSectionIds
            });
        }

        this.forceStateUpdate();
    }

    /*
     *  QUESTION
     */

    questionEditorWasRequested = (sectionLocalId, question) => {
        this.setState({
            questionEditorShown : true,
            focusedFormQuestionSectionId : sectionLocalId,
            focusedFormQuestion : question
        });
    }

    questionDidChange = (question) => {
        if (question !== undefined && question !== null) {
            let section = this.findSection(this.state.focusedFormQuestionSectionId);
            if (question.localId !== undefined) {
                for (let i = 0; i < section.questions.length; i++) {
                    if (question.localId === section.questions[i].localId) {
                        section.questions[i] = question;
                        break;
                    }
                }
            } else {
                if (section.questions === undefined) {
                    section.questions = [];
                }

                question.localId = uuidv4();
                section.questions.push(question);
            }
        }

        this.setState({
            questionEditorShown : false,
            focusedFormQuestionSectionId : undefined,
            focusedFormQuestion : undefined
        });
    }

    moveQuestion = (section, questionLocalId, direction) => {
        if (section.questions === undefined) {
            return false;
        }

        let currentIndex = ArrayUtil.findObjectIndex(section.questions, "localId", questionLocalId);

        if (currentIndex !== -1) {
            let newIndex = currentIndex + direction;
            if (newIndex < 0 || newIndex > (section.questions.length - 1)) {
                return false;
            }

            section.questions = ArrayUtil.arrayMoveImmutable(section.questions, currentIndex, newIndex);
        }

        this.forceStateUpdate();
        return true;
    }

    deleteQuestion = (section, question, confirmed) => {
        if (confirmed === undefined) {
            confirmed = false;
        }

        if (!confirmed) {
            AlertModal.showModal(
                Rosetta.string("form_editor.question_delete_title"),
                Rosetta.string("form_editor.question_delete_message", {title : question.title}),
                [
                    {
                        label : Rosetta.string("common.yes"),
                        className : "danger",
                        click : () => this.deleteQuestion(section, question, true)
                    },
                    {
                        label : Rosetta.string("common.no"),
                        click : () => AlertModal.dismissModal()
                    }
                ]
            );
        } else {
            AlertModal.dismissModal();

            // Add Question ID to array
            let deletedQuestionIds = this.state.deletedQuestionIds;
            if (question.id !== undefined && question.id !== null) {
                deletedQuestionIds.push(question.id);
            }

            // Remove Question from Form
            let questionIndex = ArrayUtil.findObjectIndex(section.questions, "localId", question.localId);
            if (questionIndex >= 0) {
                section.questions.splice(questionIndex, 1);
            }

            this.setState({
                deletedQuestionIds
            });
        }

        this.forceStateUpdate();
    }

    /*
        SUBMISSION
     */

    submissionModalWasRequested = () => {
        this.setState({
            submissionModalShown : true,
            submissionModalMode : FormSubmissionModalComponent.MODE_PROMPT
        });
    }

    submissionModalDidCallback = (type, data) => {
        if (type === FormSubmissionModalComponent.CALLBACK_TYPE_DISMISS) {
            this.setState({
                submissionModalShown : false
            });
        } else if (type === FormSubmissionModalComponent.CALLBACK_TYPE_DRAFT || type === FormSubmissionModalComponent.CALLBACK_TYPE_PUBLISH) {
            let published = type === FormSubmissionModalComponent.CALLBACK_TYPE_PUBLISH;

            this.submitFormOverNetwork(published);
        }
    }

    /*
        REMOVE
     */

    actionRemoveForm = () => {
        AlertModal.showModal(
            Rosetta.string("form_editor.form_delete"),
            Rosetta.string("form_editor.form_delete_explain"),
            [
                {
                    label : Rosetta.string("form_editor.form_delete_remove"),
                    className : "danger",
                    click : () => {
                        this.removeFormOverNetwork();
                        AlertModal.dismissModal();
                    }
                },
                {
                    label : Rosetta.string("common.cancel"),
                    click : () => {
                        AlertModal.dismissModal();
                    }
                }
            ]
        )
    }

    /* CLIENT SELECTION */

    clientSelectionWasSummoned = () => {
        this.setState({
            clientSelectionShown : true
        });
    }

    clientSelectionDidCallback = (action, data) => {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                if (this.state.form) {
                    let form = CommonUtil.cloneObject(this.state.form);
                    form.clientID = data.id;
                    form.clientName = data.company_name;
                    this.setState({
                        form
                    }, () => {
                        this.forceStateUpdate();
                    });
                }
            }

            this.setState({
                clientSelectionShown : false
            });
        }
    }

    // TODO Handle delete!
    removeFormOverNetwork = () => {
        if (this.state.formNetworkInFlight) return;
        this.setState({
            formNetworkingInFlight : true
        });

        let formData = new FormData();
        formData.append("formId", this.state.formId);

        Axios.post(ENDPOINTS.dynamicForm.removeDynamicForm, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    if (this.props.history !== undefined) {
                        this.props.history.goBack();
                    } else {
                        window.history.back();
                    }
                } else {
                    AlertModal.showModal(Rosetta.string("common.error"), API.formatError(resp));
                }

                this.setState({
                    formNetworkingInFlight : false
                });
            })
            .catch((e) => {
                this.setState({
                    formNetworkingInFlight : false
                });
            })
    }

    render() {
        let formContents = [];

        if (this.state.form != null) {
            if (this.state.form.hasOwnProperty("sections")) {
                this.state.form.sections.forEach((section) => {
                    let questionItems = [];

                    if (section.questions !== undefined) {
                        section.questions.forEach((question) => {
                            let inputItems = (
                                <FormInputComponent
                                    input={question}
                                    onClick={() => this.questionEditorWasRequested(section.localId, question.localId)}
                                    callback={(action) => this.questionDidChange(question)} />
                            );

                            questionItems.push(
                                <div className={"form-question"}>
                                    <div className={"form-question-body"}>
                                        {inputItems}
                                    </div>

                                    <div className={"form-question-actions"}>
                                        <div className={"actions"}>
                                            <div className={"action-item"} style={{backgroundImage : "url(" + edit + ")"}} onClick={() => this.questionEditorWasRequested(section.localId, question)} />
                                            <div className={"action-item"} style={{backgroundImage : "url(" + arrowUp + ")"}} onClick={() => this.moveQuestion(section, question.localId, -1)} />
                                            <div className={"action-item"} style={{backgroundImage : "url(" + arrowDown + ")"}} onClick={() => this.moveQuestion(section, question.localId, 1)} />
                                            <div className={"action-item"} style={{backgroundImage : "url(" + trash + ")"}} onClick={() => this.deleteQuestion(section, question, false)} />
                                        </div>
                                    </div>
                                </div>
                            )
                        });
                    }

                    let sectionBadges = [];

                    if (parseInt(this.getOrDefault(section, "formInForm", 0)) === 1) {
                        sectionBadges = (
                            <span className={"badge bg-secondary"}>{Rosetta.string("form_section.editor_form_in_form")}</span>
                        )
                    }

                    formContents.push(
                        <div className={"card mt-3 form-section"} key={section.localId}>
                            <div className={"card-header"}>
                                <div className={"form-section-title"}>
                                    {section.title} {sectionBadges}
                                </div>
                                <div className={"actions"}>
                                    <span className={"action-item"} onClick={() => this.formSectionEditorWasRequested(section)} style={{backgroundImage : "url(" + edit + ")"}} />
                                    <span className={"action-item"} style={{backgroundImage : "url(" + arrowUp + ")"}} onClick={() => this.moveSection(section.localId, -1)} />
                                    <span className={"action-item"} style={{backgroundImage : "url(" + arrowDown + ")"}} onClick={() => this.moveSection(section.localId, 1)} />
                                    <span className={"action-item"} style={{backgroundImage : "url(" + trash + ")"}} onClick={() => this.deleteSection(section, false)} />
                                </div>
                            </div>

                            <div className={"card-body"}>

                                <div className={"row mt-2"}>
                                    <div className={"col-12"}>
                                        {questionItems}
                                    </div>
                                </div>

                                <div className={"row mt-2"}>
                                    <div className={"col-12 text-center"}>
                                        <button className={"btn btn-primary"} onClick={() => this.questionEditorWasRequested(section.localId, undefined)}>{Rosetta.string("form_editor.section_add_question")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                });
            }
        }

        let deleteButton = [];
        if (this.state.form !== undefined && this.state.form !== null && this.state.form.id !== undefined) {
            deleteButton = (
                <button className={"btn btn-danger"} onClick={this.actionRemoveForm}>{Rosetta.string("form_editor.form_delete")}</button>
            )
        }

        return (
            <div className={"form-editor-component"}>
                <div className={"container-fluid pb-4"}>

                    <div className={"row justify-content-center mt-4"}>
                        <div className={"col-12 col-md-8 col-lg-6"}>
                            <div className={"card"}>
                                <div className={"card-body"}>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("form_editor.form_client")}</label>
                                            <div className={"form-control clickable"} onClick={() => this.clientSelectionWasSummoned()}>{this.getFormProperty("clientName", "---")}</div>
                                        </div>
                                    </div>

                                    <div className={"row"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("form_editor.form_title")}</label>
                                            <input type={"text"} className={"form-control"} name={"title"} value={this.getFormProperty("title", "")} onChange={(e) => this.setFormProperty(e.target.name, e.target.value)} />
                                         </div>
                                    </div>

                                    <div className={"row mt-2"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("form_editor.description")}</label>
                                            <textarea className={"form-control"} name={"description"} value={this.getFormProperty("description", null)} onChange={(e) => this.setFormProperty(e.target.name, e.target.value)} />
                                        </div>
                                    </div>

                                    <div className={"row mt-2"}>
                                        <div className={"col-12"}>
                                            <label>{Rosetta.string("form_editor.form_version", { version : this.getFormProperty("version", "") })}</label>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"col-12"}>
                            {formContents}
                        </div>

                        <div className={"col-12 text-center mt-4"}>
                            <button className={"btn btn-primary"} onClick={() => this.formSectionEditorWasRequested(undefined)}>{Rosetta.string("form_editor.form_add_section")}</button>
                        </div>
                    </div>

                </div>

                <div className={"save-follow"}>
                    <div className={"container"}>
                        <div className={"row"}>
                            <div className={"col-12"}>
                                <span className={"btn btn-success"} onClick={this.submissionModalWasRequested}>{Rosetta.string("common.save")}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <FormSubmissionModalComponent
                    shown={this.state.submissionModalShown}
                    mode={this.state.submissionModalMode}
                    isPublishing={this.state.published}
                    error={this.state.formSubmissionModalError}
                    callback={this.submissionModalDidCallback} />

                <FormSectionEditorComponent
                    shown={this.state.formSectionEditorShown}
                    section={this.state.focusedFormSection}
                    callback={this.sectionDidChange} />

                <FormQuestionInputEditor
                    shown={this.state.questionEditorShown}
                    input={this.state.focusedFormQuestion}
                    callback={this.questionDidChange} />

                <ClientSelectionModal
                    shown={this.state.clientSelectionShown}
                    callback={this.clientSelectionDidCallback} />

            </div>
        )
    }

}
