import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {FilterText} from "../../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../../table/TableComponent";
import {Chronos} from "../../../../chronos/Chronos";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import AlertModal from "../../../alertmodal/AlertModal";
import {Toast} from "../../../toast/TokyoToaster";
import {Navigator} from "../../../../util/Navigator";

export const FormListScreen = (props) => {

    const [forms, setForms] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [formsNetworkInFlight, setFormsNetworkInFlight] = useState(false);

    const [page, setPage] = useState(1);

    const [formTypes, setFormTypes] = useState([]);
    const [formTypesNetworkInFlight, setFormTypesNetworkInFlight] = useState(false);

    const [filterFormType, setFilterFormType] = useState(-1);
    const [filterKeyword, setFilterKeyword] = useState("");

    useEffect(() => {
        getFormsFromNetwork();
        getFormTypesFromNetwork();
    }, []);

    useEffect(() => {
        getFormsFromNetwork();
    }, [page, filterKeyword, filterFormType]);

    function moveToEditor(id) {
        if (id === undefined) {
            id = "new";
        }
        Navigator.navigate("/content-config/form/" + id);
    }

    function tableRowWasClicked(row) {
        moveToEditor(row.id);
    }

    function getFormsFromNetwork() {
        if (formsNetworkInFlight) return;
        setFormsNetworkInFlight(true);

        let data = {
            page,
            keyword : filterKeyword
        };

        if (parseInt(filterFormType) !== -1) {
            data.formTypeIDs = [filterFormType];
        }

        Axios.post(ENDPOINTS.form.getFormTemplates, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setForms(resp.data.forms);
                    setTotalCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setFormsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFormsNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "FEL1000C" }));
            });
    }

    function getFormTypesFromNetwork() {
        if (formTypesNetworkInFlight) return;
        setFormTypesNetworkInFlight(true);

        Axios.get(ENDPOINTS.form.getFormTypes)
            .then((r) => {
                const resp = API.parse(r);

                if (resp.success) {
                    setFormTypes(resp.data.formTypes);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setFormTypesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setFormTypesNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    Rosetta.string("common.error_common_unknown", { error_code : "FEL2000C" }),
                    Toast.ERROR,
                    Toast.LONG
                );
            });
    }

    return (
        <div className={"app-screen form-list-screen"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("forms_list.title")} />
                </div>

                <div className={"col-12 col-md-4 text-end"}>
                    <span className={"btn btn-secondary"} onClick={() => Navigator.navigate("/content-config/form-submittables/")}>{Rosetta.string("forms_list.action_submittables")}</span>
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-4"}>
                        <label>{Rosetta.string("clients.list_filter_date")}</label>
                        <select className={"form-select"} onChange={(e) => setFilterFormType(e.target.value)}>
                            <option value={-1}>{Rosetta.string("forms_list.filter_type_none")}</option>
                            {
                                formTypes.map((type) => (
                                    <option value={type.id}>{type.name}</option>
                                ))
                            }
                        </select>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <label>&nbsp;</label>
                        <FilterText callback={setFilterKeyword} />
                    </div>

                    <div className={"col-12 col-md-4 text-end"}>
                        <label>&nbsp;</label>
                        <div>
                            <button className={"btn btn-primary"} onClick={() => moveToEditor("new")}>{Rosetta.string("forms_list.action_new")}</button>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={forms}
                                placeholderMode={formsNetworkInFlight}
                                placeholderRows={20}
                                onRowClick={tableRowWasClicked}>

                                <Column name={"formTypeName"} title={Rosetta.string("forms_list.table_type")} />
                                <Column name={"name"} title={Rosetta.string("forms_list.table_name")} />
                                <Column name={"version"} title={Rosetta.string("forms_list.table_version")} />
                                <Column name={"dateCreated"} title={Rosetta.string("forms_list.table_last_update")} render={(data, row) => {
                                    let out = "";

                                    if (data !== null) {
                                        let chronos = Chronos.withTimestampSeconds(data);
                                        if (chronos && chronos.isValid()) {
                                            out = chronos.format("dd/MM/yyyy HH:mm");
                                        }
                                    }

                                    return out;
                                }} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={totalCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}