import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {FilterText} from "../../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../../table/TableComponent";
import {Chronos} from "../../../../chronos/Chronos";
import {useEffect, useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import AlertModal from "../../../alertmodal/AlertModal";
import {CommonUtil} from "../../../../util/CommonUtil";
import {TimeUtil} from "../../../../util/TimeUtil";
import {ClientSelectionModal} from "../../common/ClientSelectionModal";
import {OffcanvasActions} from "../../../offcanvas/Offcanvas";
import {Navigator} from "../../../../util/Navigator";

export const FormSubmittableListScreen = (props) => {

    const [submittables, setSubmittables] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [submittablesNetworkInFlight, setSubmittablesNetworkInFlight] = useState(false);

    const [page, setPage] = useState(1);

    const [filterClient, setFilterClient] = useState(null);
    const [filterKeyword, setFilterKeyword] = useState("");

    const [clientSelectorShown, setClientSelectorShown] = useState(false);

    useEffect(() => {
        getProjectSubmittablesFromNetwork();
    }, []);

    useEffect(() => {
        getProjectSubmittablesFromNetwork();
    }, [page, filterClient, filterKeyword]);

    function moveToEditor(id) {
        if (id === undefined) {
            id = "new";
        }
        Navigator.navigate("/content-config/form-submittable/" + id);
    }

    function tableRowWasClicked(row) {
        moveToEditor(row.id);
    }

    function clientSelectorDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            if (data) {
                setFilterClient(data);
            }
            setClientSelectorShown(false);
        }
    }

    function getProjectSubmittablesFromNetwork() {
        if (submittablesNetworkInFlight) return;
        setSubmittablesNetworkInFlight(true);

        let data = {
            page
        };

        if (filterClient !== null) {
            data.clientID = filterClient.id;
        }

        if (filterKeyword && filterKeyword !== "") {
            data.keyword = filterKeyword
        }

        Axios.post(ENDPOINTS.projectForm.getProjectSubmittablesAdmin, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setSubmittables(resp.data.submittables);
                    setTotalCount(resp.data.count);
                } else {
                    AlertModal.showError(API.formatError(resp));
                }
                setSubmittablesNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setSubmittablesNetworkInFlight(false);
                AlertModal.showError(Rosetta.string("common.error_common_unknown", { error_code : "PSE1000C"}));
            });
    }

    let clientClear = [];
    if (filterClient !== null) {
        clientClear = (
            <span className={"badge bg-secondary clickable"} onClick={() => setFilterClient(null)}>{Rosetta.string("common.clear")}</span>
        )
    }

    return (
        <div className={"app-screen form-submittables-list-screen"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("projectSubmittable.title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-4"}>
                        <label>{Rosetta.string("projectSubmittable.filter_client")} {clientClear}</label>
                        <div className={"form-control clickable"} onClick={() => setClientSelectorShown(true)}>
                            {CommonUtil.getOrDefault(filterClient, "company_name", Rosetta.string("projectSubmittable.filter_client_all"))}
                        </div>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <label>&nbsp;</label>
                        <FilterText callback={setFilterKeyword} />
                    </div>

                    <div className={"col-12 col-md-4 text-end"}>
                        <label>&nbsp;</label>
                        <div>
                            <button className={"btn btn-primary"} onClick={() => moveToEditor("new")}>{Rosetta.string("projectSubmittable.new_submittable")}</button>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={submittables}
                                placeholderMode={submittablesNetworkInFlight}
                                placeholderRows={20}
                                onRowClick={tableRowWasClicked}>

                                <Column name={"clientName"} title={Rosetta.string("projectSubmittable.table_client")} />
                                <Column name={"title"} title={Rosetta.string("projectSubmittable.table_title")} />
                                <Column name={"description"} title={Rosetta.string("projectSubmittable.table_description")} />
                                <Column name={"validityDuration"} className={"text-center"} title={Rosetta.string("projectSubmittable.table_validity_duration")} render={(data, row) => {
                                    if (data !== null && data > 0) {
                                        return TimeUtil.prettyFormatTimeDuration(data, {top: 2});
                                    }
                                    return "---";
                                }} />
                                <Column name={"projectFormTypeName"} className={"text-center"} title={Rosetta.string("projectSubmittable.table_validation_form_type")} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={totalCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ClientSelectionModal
                shown={clientSelectorShown}
                callback={clientSelectorDidCallback} />

        </div>
    );

}