import {useState} from "react";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../network/API";
import {DataManager} from "../../../data/DataManager";

import "./LoginScreen.css";
import heroImage from "../../../assets/images/hero_people.svg";
import logo from "../../../assets/images/header_logo_2.svg";
import {ImageUtil} from "../../../util/ImageUtil";
import {NavLink} from "react-router-dom";
import Rosetta from "../../../rosetta/Rosetta";
import {UIBlocker} from "../../loading/UIBlocker";
import AlertModal from "../../alertmodal/AlertModal";
import {OffcanvasActions} from "../../offcanvas/Offcanvas";
import {ForgottenPasswordModal} from "./ForgottenPasswordModal";
import {EventUtil} from "../../../util/EventUtil";
import {AppUser} from "../../../util/AppUser";

export const LoginScreen = () => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginNetworkInFlight, setLoginNetworkInFlight] = useState(false);

    const [forgottenPasswordShown, setForgottenPasswordShown] = useState(false);

    function forgottenPasswordDidCallback(action, data) {
        if (action === OffcanvasActions.CLOSE) {
            setForgottenPasswordShown(false);
        }
    }

    function showUserInfoDialog() {
        AlertModal.showModal(
            Rosetta.string("login.new_user_title"),
            Rosetta.string("login.new_user_message")
        );
    }

    function submitLoginOverNetwork(e) {
        e.preventDefault();
        e.stopPropagation();

        if (loginNetworkInFlight) return;
        setLoginNetworkInFlight(true);

        if (username.trim() === "") {
            showError(Rosetta.string("login.validation_username"));
            return;
        }

        if (password.trim() === "") {
            showError(Rosetta.string("login.validation_password"));
            return;
        }

        let requireUserRoles = [AppUser.roles.SITE_MANAGER, AppUser.roles.LEAD_ADMIN, AppUser.roles.SYSTEM_ADMIN];
        if (process.env.REACT_APP_MODE === "cms") {
            requireUserRoles = [AppUser.roles.SYSTEM_ADMIN];
        }

        let formData = new FormData();
        formData.append("emailAddress", username);
        formData.append("password", password);
        formData.append("restrictUserType", JSON.stringify(requireUserRoles));

        Axios.post(ENDPOINTS.auth.login, formData)
            .then((r) => {
                let resp = API.parse(r);
                if (resp.success) {
                    DataManager.setUser(resp.data.user);
                    DataManager.setSessionToken(resp.data.sessionToken);

                    // Await for DataManager callbacks to filter out, MainComponent will get us.
                } else {
                    showError(API.formatError(resp));
                }

                setLoginNetworkInFlight(false);
            })
            .catch((e) => {
                showError(Rosetta.string("common.error_common_unknown", {error_code : "LG1000C"}));
                setLoginNetworkInFlight(false);
                console.log(e);
            })
    }

    function showError(message) {
        AlertModal.showModal(
            Rosetta.string("common.error"),
            message
        );
    }

    return (
        <div className={"app-screen login-screen"}>
            <div className={"container-fluid"}>
                <div className={"row"}>
                    <div className={"col-1 col-md-4 col-lg-6 fancy-panel"}>
                        <div className={"fancy-image"} style={{backgroundImage : ImageUtil.background(heroImage)}} />
                        <div className={"vertical-shadow"} />
                    </div>
                    <div className={"col-11 col-md-8 col-lg-6 form-area"}>
                        <div className={"col-1 col-md-2 col-lg-3"} />
                        <div className={"col-10 col-md-8 col-lg-6"}>

                            <form onSubmit={submitLoginOverNetwork}>
                                <div className={"logo"} style={{backgroundImage : ImageUtil.background(logo)}} />

                                <div className={"form-row"}>
                                    <label>{Rosetta.string("login.username")}</label>
                                    <input type={"text"} className={"form-control"} value={username} onChange={(e) => setUsername(e.target.value)} />
                                </div>

                                <div className={"form-row"}>
                                    <label>{Rosetta.string("login.password")}</label>
                                    <input type={"password"} className={"form-control"} value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>

                                <div className={"form-row"}>
                                    <button type={"submit"} className={"btn btn-primary"}>{Rosetta.string("login.login")}</button>
                                </div>

                                <div className={"form-row"}>
                                    <a href={"#"} onClick={(e) => { EventUtil.cancel(e); setForgottenPasswordShown(true); }}>{Rosetta.string("login.forgotten_password")}</a>
                                </div>

                                <div className={"form-row"}>
                                    <span className={"btn"} onClick={() => showUserInfoDialog()}>{Rosetta.string("login.new_user_prompt")}</span>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <UIBlocker shown={loginNetworkInFlight} />

            <ForgottenPasswordModal
                shown={forgottenPasswordShown}
                callback={forgottenPasswordDidCallback} />

        </div>
    );

}