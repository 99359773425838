import {ScreenTitle} from "../../screenTitle/ScreenTitle";
import Rosetta from "../../../../rosetta/Rosetta";
import {FilterText} from "../../../filter/FilterText";
import TableComponent, {Column, TablePaginator} from "../../../table/TableComponent";
import {useEffect, useState} from "react";
import {Chronos} from "../../../../chronos/Chronos";
import {Navigator} from "../../../../util/Navigator";
import Axios from "axios";
import {API, ENDPOINTS} from "../../../../network/API";
import {Toast} from "../../../toast/TokyoToaster";

export const ClientsLandingComponent = (props) => {

    const [filterKeyword, setFilterKeyword] = useState();
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [clients, setClients] = useState([]);
    const [startDate, setStartDate] = useState(null);

    const [clientsNetworkInFlight, setClientsNetworkInFlight] = useState(false);

    useEffect(() => {
        fetchClientFromNetwork();
    }, []);

    useEffect(() => {
        fetchClientFromNetwork();
    }, [page, filterKeyword, startDate])

    function timeframeDidChange(value) {
        if (value > 0) {
            setStartDate(Chronos.now().add(value * -1, Chronos.MONTHS).seconds());
        } else {
            setStartDate(null);
        }
    }

    function moveToEditor(id) {
        Navigator.navigate("/client/" + (id ? id : ""));
    }

    function tableRowWasClicked(row) {
        if (row) {
            moveToEditor(row.id);
        }
    }

    function fetchClientFromNetwork() {
        if (clientsNetworkInFlight) return;
        setClientsNetworkInFlight(true);

        const data = {
            page
        };

        if (filterKeyword !== null && filterKeyword !== "") {
            data.keyword = filterKeyword;
        }

        if (startDate !== null) {
            data.startDate = startDate;
        }

        Axios.post(ENDPOINTS.client.getClients, data)
            .then((r) => {
                const resp = API.parse(r);
                if (resp.success) {
                    setClients(resp.data.clients);
                    setTotalCount(resp.data.count);
                } else {
                    Toast.show(
                        Rosetta.string("common.error"),
                        API.formatError(resp),
                        Toast.ERROR,
                        Toast.LONG
                    );
                }
                setClientsNetworkInFlight(false);
            })
            .catch((e) => {
                console.log(e);
                setClientsNetworkInFlight(false);
                Toast.show(
                    Rosetta.string("common.error"),
                    Rosetta.string("common.error_generic_unknown", {error_code : "SAC1000"}),
                    Toast.ERROR,
                    Toast.LONG
                );
            });
    }

    return (
        <div className={"app-screen diary-register-component"}>

            <div className={"row"}>
                <div className={"col-12 col-md-8"}>
                    <ScreenTitle title={Rosetta.string("clients.list_title")} />
                </div>
            </div>

            <div className={"animate-screen-content"}>
                <div className={"row mt-4"}>
                    <div className={"col-12 col-md-4"}>
                        <label>{Rosetta.string("clients.list_filter_date")}</label>
                        <select className={"form-select"} onChange={(e) => timeframeDidChange(e.target.value)}>
                            <option value={0}>{Rosetta.string("clients.list_filter_date_none")}</option>
                            <option value={1}>{Rosetta.string("clients.list_filter_date_month")}</option>
                            <option value={3}>{Rosetta.string("clients.list_filter_date_three_month")}</option>
                            <option value={6}>{Rosetta.string("clients.list_filter_date_six_months")}</option>
                            <option value={12}>{Rosetta.string("clients.list_filter_date_twelve_months")}</option>
                        </select>
                    </div>

                    <div className={"col-12 col-md-4"}>
                        <label>&nbsp;</label>
                        <FilterText callback={setFilterKeyword} />
                    </div>

                    <div className={"col-12 col-md-4 text-end"}>
                        <label>&nbsp;</label>
                        <div>
                            <button className={"btn btn-primary"} onClick={() => moveToEditor("new")}>{Rosetta.string("clients.list_new_company")}</button>
                        </div>
                    </div>
                </div>

                <div className={"row mt-4"}>
                    <div className={"col-12"}>
                        <div className={"table-container"}>
                            <TableComponent
                                className={"clickable"}
                                data={clients}
                                placeholderMode={clientsNetworkInFlight}
                                placeholderRows={20}
                                onRowClick={tableRowWasClicked}>

                                <Column name={"company_name"} title={Rosetta.string("clients.list_company_name")} />
                                <Column name={"company_owner"} title={Rosetta.string("clients.list_company_owner")} />
                                <Column name={"address1"} title={Rosetta.string("clients.list_company_address")} />
                                <Column name={"subscriptionTypeName"} title={Rosetta.string("clients.list_company_subscription_type")} />
                                <Column name={"dateCreated"} title={Rosetta.string("clients.list_company_date_created")} render={(data, row) => {
                                    let out = "";

                                    if (data !== null) {
                                        let chronos = Chronos.withTimestampSeconds(data);
                                        if (chronos && chronos.isValid()) {
                                            out = chronos.format("dd/MM/yyyy");
                                        }
                                    }

                                    return out;
                                }} />

                            </TableComponent>

                            <div className={"ep-table-paginate-container mt-1"}>
                                <div className={"push"} />
                                <TablePaginator
                                    page={page}
                                    totalCount={totalCount}
                                    pageSize={20}
                                    onClick={setPage} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )

}