import "./TokyoNavigationList.css";
import {NavLink} from "react-router-dom";

export const TokyoNavigationList = (props) => {

    let mainContent = [];

    function handleCallback(action, item) {
        if (props.callback !== undefined) {
            props.callback(action, item);
        }
    }

    if (props.items !== undefined) {
        for (let i = 0; i < props.items.length; i++) {
            let header = props.items[i];
            // mainContent.push(
            //     <div className={"navigation-heading"}>{header.title}</div>
            // );

            for (let x = 0; x < header.items.length; x++) {
                let item = header.items[x];
                mainContent.push(
                    <NavLink
                        className={"navigation-item"}
                        to={item.url} end
                        activeClassName={"active"}
                        onClick={() => handleCallback("click", item)}>
                        {item.title}
                    </NavLink>
                );
            }
        }
    }

    return (
        <div className={"tokyo-navigation-list"}>
            {mainContent}
        </div>
    )

}