import {ImageUtil} from "../../util/ImageUtil";

import "./SettingsPanelContent.css";

import accountDefault from "../../assets/images/default_profile.svg";
import {DataManager} from "../../data/DataManager";
import {EmergingButton} from "../button/EmergingButton";
import {TokyoNavigationList} from "../navigationlist/TokyoNavigationList";
import Rosetta from "../../rosetta/Rosetta";
import {AppUser} from "../../util/AppUser";

export const SettingsPanelContent = (props) => {

    let user = DataManager.getUser();
    let userName = "";
    if (user !== null) {
        userName = Rosetta.string("common.name_format", { given_name : user.firstName, family_name : user.surname });
    }

    function handleCallback(action) {
        if (props.callback !== undefined) {
            props.callback(action);
        }
    }

    let navigationItems = [];

    if (!(process.env.REACT_APP_MODE === "cms")) {
        navigationItems = [
            {
                title: Rosetta.string("system_settings.option_users"),
                url: "/settings/users"
            },
            {
                title: Rosetta.string("system_settings.option_projects"),
                url: "/settings/projects"
            },
            {
                title: Rosetta.string("system_settings.option_company_details"),
                url: "/settings/company-details"
            }
        ];
    }

    if (user !== null) {
        if (parseInt(user.userRoleId) === AppUser.roles.LEAD_ADMIN || parseInt(user.userRoleId) === AppUser.roles.SYSTEM_ADMIN) {
            navigationItems.push({
                title : Rosetta.string("system_settings.option_subscription"),
                url : "/settings/subscription-details"
            });
        }
    }

    return (
        <div className={"settings-side-panel-content"}>
            <div className={"side-settings-profile-panel"}>
                <div className={"account-circle"} style={{backgroundImage : ImageUtil.background(accountDefault)}} />
                <div className={"info-area"}>
                    <span className={"profile-name"}>{userName}</span>
                    <span className={"profile-subtitle"}>
                        <EmergingButton pill={true}>
                            <button className={"btn"} onClick={() => handleCallback("signout")}>{Rosetta.string("common.sign_out")}</button>
                        </EmergingButton>
                    </span>
                </div>
            </div>

            <TokyoNavigationList
                callback={() => handleCallback("close")}
                items={[
                    {
                        title : Rosetta.string("system_settings.title"),
                        items : navigationItems
                    }
                ]} />
        </div>
    )

}